import React, { useState } from "react";
import { FaShareSquare } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Url } from "../functions/Url";

const CampaignTable = ({ data, setData }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [itemData, setItemData] = useState();
    const token = localStorage.getItem("token");

  const deleteCampaignRow = async (campaignId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.delete(
        `${Url}campaigns/delete/${campaignId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setData();
      }
    } catch (error) {
      console.error("Error deleting campaign:", error);
      alert("Failed to delete campaign");
    }
  };

  const handleOpenFile = async (campaignId) => {
    try {
      const response = await axios.get(`${Url}campaigns/iq/${campaignId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          }
          );
      const signedUrl = response.data.signedUrl;

      // Open the URL in a new tab, which will prompt the browser to download the file
      window.open(signedUrl, "_blank");
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      alert("Failed to open the file");
    }
  };
  const handleOpenFileNurture = async (campaignId) => {
    try {
      const response = await axios.get(`${Url}campaigns/nurture/${campaignId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json"
            }
          }
          );
      const signedUrl = response.data.signedUrl;

      // Open the URL in a new tab, which will prompt the browser to download the file
      window.open(signedUrl, "_blank");
    } catch (error) {
      console.error("Error fetching signed URL:", error);
      alert("Failed to open the file");
    }
  };

  const editCampaignRow = async (campaignId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${Url}campaigns/edit/${campaignId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setData();
      }
    } catch (error) {
      console.error("Error updating campaign status:", error);
      alert("Failed to update campaign status.");
    }
  };

  const wantToContinue = (id) => {
    editCampaignRow(id);
    setConfirmationModal(false);
    setItemData();
  };
  const confirmationModalHandler = (data) => {
    setConfirmationModal(!confirmationModal);
    setItemData(data);
  };

  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr
            className="text-secondary text-center"
            style={{ fontSize: "13px" }}
          >
            <th scope="col">Campaign Name</th>
            <th scope="col">Upload Data</th>
            <th scope="col"># of Leads</th>
            <th scope="col">Status</th>
            <th scope="col">Prime</th>
            <th scope="col">Select</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item._id} className="text-center">
                <th scope="row">{item.campaignName}</th>
                <td>{item.uploadedDate}</td>
                <td>{item.numOfRows}</td>
                <td>
                  {item.status === "Process Leads" ? (
                    <button
                      type="button"
                      onClick={() => confirmationModalHandler(item)}
                      className="rounded-pill btn button-bg-warning-danger status-pill p-1 ps-4 pe-4"
                    >
                      Process Leads
                    </button>
                  ) : item.status === "Processing" ? (
                    <span className="rounded-pill button-bg-warning status-pill p-1 ps-4 pe-4">
                      Processing
                    </span>
                  ) : (
                    <span className="rounded-pill button-bg-info status-pill p-1 ps-4 pe-4">
                      Complete
                    </span>
                  )}
                </td>
                {item.iq1Count.length > 0 ? (
                  <td>
                    {item.iq1Count}{" "}
                    <span className="p-1">
                      <button
                        type="button"
                        onClick={() => handleOpenFile(item._id)}
                        className="share-square-btn"
                      >
                        <FaShareSquare />
                      </button>
                    </span>
                  </td>
                ) : (
                  <td>...</td>
                )}
                {item.nurture.length > 0 ? (
                  <td>
                    {item.nurtureCount}{" "}
                    <span className="p-1">
                      <button
                        type="button"
                        onClick={() => handleOpenFileNurture(item._id)}
                        className="share-square-btn"
                      >
                        <FaShareSquare />
                      </button>
                    </span>
                  </td>
                ) : (
                  <td>...</td>
                )}
                {item.status === "Process Leads" ? (
                  <td>
                    <span className="p-1">
                      <button
                        type="button"
                        className="trash-btn"
                        onClick={() => deleteCampaignRow(item._id)}
                      >
                        <FaRegTrashCan />
                      </button>
                    </span>
                  </td>
                ) : null}
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        show={confirmationModal}
        onHide={() => setConfirmationModal((prev) => !prev)}
        className="modal-dialog-custom confirmation-modal"
        centered
      >
        <Modal.Body className="p-0">
          <div className="text-center">
            <h5 className="modal-title mx-auto campaignInfo-modalLabel text-dark">
              Your leads have been uploaded successfully.{" "}
              <p className="text-break">Do you want to process now?.</p>
            </h5>
            <button
              onClick={() => wantToContinue(itemData._id)}
              type="button"
              className="rounded-pill campaignInfo-save-btn"
            >
              Yes
            </button>
            <div
              onClick={() => setConfirmationModal((prev) => !prev)}
              className="form-text mt-2"
              style={{ cursor: "pointer" }}
            >
              Maybe later
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default CampaignTable;
