import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import logo from "../assets/img/prospect-logo.png";
import { Url } from "../functions/Url";

function SignUp() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const validate = () => {
    let tempErrors = {};
    if (!formData.firstName) tempErrors.firstName = "First name is required.";
    if (!formData.lastName) tempErrors.lastName = "Last name is required.";
    if (!formData.companyName)
      tempErrors.companyName = "Company name is required.";
    if (!formData.email) tempErrors.email = "Email is required.";
    if (!formData.phone) tempErrors.phone = "Phone number is required.";
    if (!formData.password) tempErrors.password = "Password is required.";
    if (formData.password.length < 8)
      tempErrors.password = "Password must be at least 8 characters.";
    if (formData.password !== formData.confirmPassword)
      tempErrors.confirmPassword = "Passwords do not match.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch(`${Url}user/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Registration failed.");
      }

      const data = await response.json();
      console.log("User registered successfully", data);
      navigate("/");
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="piq-body">
      <div className="container pt-5 pb-5">
        <div className="mx-auto" style={{ maxWidth: "500px" }}>
          <div className="card shadow border card-rounded">
            <div className="card-body p-5">
              <div className="col-md-4 px-0">
                <img src={logo} className="img-fluid" alt="Prospect Logo" />
              </div>

              <h3 className="form-label mt-3">Sign Up</h3>
              <div className="form-text">
                Fill in your details below and continue signing up
              </div>
              <form className="mt-3" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">First Name*</label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control input-rounded"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                    {errors.firstName && (
                      <div className="text-danger">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Last Name*</label>
                    <input
                      type="text"
                      name="lastName"
                      className="form-control input-rounded"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                    {errors.lastName && (
                      <div className="text-danger">{errors.lastName}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Company Name*</label>
                    <input
                      type="text"
                      name="companyName"
                      className="form-control input-rounded"
                      value={formData.companyName}
                      onChange={handleChange}
                      required
                    />
                    {errors.companyName && (
                      <div className="text-danger">{errors.companyName}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Email*</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control input-rounded"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Phone*</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control input-rounded"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      onKeyPress={(e) =>
                        /[0-9]/.test(e.key) || e.preventDefault()
                      }
                    />
                    {errors.phone && (
                      <div className="text-danger">{errors.phone}</div>
                    )}
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">Password*</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control input-rounded"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                    {errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Confirm Password*</label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control input-rounded"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                    {errors.confirmPassword && (
                      <div className="text-danger">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
                {apiError && <div className="text-danger">{apiError}</div>}
                <div className="d-grid gap-2 col-12 mb-3 mx-auto">
                  <button
                    className="btn btn-primary button-rounded"
                    type="submit"
                  >
                    Create Account
                  </button>
                </div>
                <div className="form-text text-center mb-2">
                  Already have an account?
                  <span>
                    <a href="#" className="anchor-button-color ms-2">
                      Sign in
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
