import React from "react";

const Modals = () => {
  const uploadSuccessModal = () => {
    // Add your logic to show the modal
  };

  const maybeLaterModal = () => {
    // Add your logic for maybe later action
  };

  return (
    <>
      <div
        className="modal fade"
        id="campaignInfoModal"
        tabIndex="-1"
        aria-labelledby="campaignInfoModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "350px" }}
        >
          <div className="modal-content campaignInfo-modalContent p-3">
            <div className="text-center">
              <h5 className="modal-title mx-auto campaignInfo-modalLabel text-dark">
                Your leads have been uploaded successfully.
                <p className="text-break">Do you want to process now?</p>
              </h5>
              <button
                type="button"
                className="rounded-pill campaignInfo-save-btn"
              >
                Yes
              </button>
              <div
                className="form-text mt-2"
                onClick={maybeLaterModal}
                style={{ cursor: "pointer" }}
              >
                Maybe later
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="campaignProcessLeads"
        tabIndex="-1"
        aria-labelledby="campaignProcessLeadsModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "350px" }}
        >
          <div className="modal-content campaignInfo-modalContent">
            <div className="d-grid gap-2 d-flex justify-content-end">
              <button
                type="button"
                className="btn-close campaignInfo-btn-rounded-circle"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="text-center p-3 pt-0">
              <h5 className="modal-title mx-auto campaignInfo-modalLabel text-dark">
                Your list has been saved.
                <p className="text-break mt-2">
                  To send to Processing, please click Process Leads button when
                  you are ready.
                </p>
              </h5>
              <button type="button" className="campaignInfo-ok-btn">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="addCampaignModal"
        tabIndex="-1"
        aria-labelledby="addCampaignModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "350px" }}
        >
          <div
            className="modal-content campaignInfo-modalContent"
            style={{ border: "0px!important" }}
          >
            <div className="card campaign-card-rounded p-1">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      name="name_campaign"
                      placeholder="Name of Campaign"
                      className="form-control campaign-input-rounded"
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    className="campaign-button-rounded campaignInfoModal"
                    onClick={uploadSuccessModal}
                    type="button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="uploadCampaignModal"
        tabIndex="-1"
        aria-labelledby="uploadCampaignModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "350px" }}
        >
          <div
            className="modal-content campaignInfo-modalContent"
            style={{ border: "0px!important" }}
          >
            <div className="card campaign-card-rounded p-1">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <input
                      type="text"
                      name="name_campaign"
                      placeholder="Name your Campaign"
                      className="form-control campaign-input-rounded"
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label
                      className="form-control campaign-input-rounded"
                      onClick={() =>
                        document
                          .getElementById("upload_file_input_field")
                          .click()
                      }
                      id="upload_file_label"
                    >
                      Select File
                    </label>
                    <input
                      type="file"
                      id="upload_file_input_field"
                      name="select_file"
                      placeholder="Select File"
                      style={{ display: "none" }}
                      onChange={uploadSuccessModal}
                    />
                  </div>
                </div>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    className="campaign-button-rounded"
                    onClick={uploadSuccessModal}
                    type="button"
                  >
                    <span className="me-2">
                      <i className="fa fa-upload" aria-hidden="true"></i>
                    </span>
                    Upload Campaign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modals;
