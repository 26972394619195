import React, { useRef, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import uploadFIleIcon from "../assets/img/upload-file-icon.png";
import "../assets/css/style.css";
import { FaUpload } from "react-icons/fa";
import { Url } from "../functions/Url";

const UploadSection = ({ data, setData }) => {
  const [showModal, setShowModal] = useState(false);
  const [showFileModal, setShowFileModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [itemData, setItemData] = useState();
  const [fileName, setFileName] = useState();
  const [campaignName, setCampaignName] = useState("");
  const [fileDroped, setFileDroped] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const inputRef = useRef();

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (
      file && (file.type === "text/csv")
      // (file.type === "application/vnd.ms-excel" ||
      //   file.type ===
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      //   file.type === "text/csv")
    ) {
      setSelectedFile(file);
      setFileDroped(true);
      setShowModal(true);
    } else {
      alert("Only CSV files are allowed.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (
      file && (file.type === "text/csv")
      // (file.type === "application/vnd.ms-excel" ||
      //   file.type ===
      //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      //   file.type === "text/csv")
    ) {
      setSelectedFile(file);
      if (!showFileModal) {
        setShowModal(true);
      } else {
        setFileName(file.name);
      }
    } else {
      alert("Only CSV files are allowed.");
    }
  };

  const handleUploadClick = () => {
    setShowFileModal(true);
  };

  const handleSave = async () => {
    if (campaignName && selectedFile) {
      try {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("campaignName", campaignName);

        const response = await axios.post(`${Url}campaigns/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        const fetchedData = response.data;
        setData();
        setCampaignName("");
        setShowModal(false);
        setShowFileModal(false);
        setFileName();
        setConfirmationModal(true);
        setItemData(fetchedData.newCampaign);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      alert("Please provide a campaign name and select a file.");
    }
  };

  console.log(itemData);
  const handleClose = () => {
    setShowModal(false);
    setCampaignName("");
    setSelectedFile(null);
  };

  const handleFileClose = () => {
    setShowFileModal(false);
    setCampaignName("");
    setSelectedFile(null);
  };

  const editCampaignRow = async (campaignId) => {
    try {
      const token = localStorage.getItem("token");

      const response = await axios.put(
        `${Url}campaigns/edit/${campaignId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setData();
      }
    } catch (error) {
      console.error("Error updating campaign status:", error);
      alert("Failed to update campaign status.");
    }
  };

  const statusProcesshandler = () => {
    editCampaignRow(itemData._id);
    setConfirmationModal(false);
    setItemData();
  };

  return (
    <section id="drag-and-drop-campaign-section">
      {!data ||
        (data.length === 0 && (
          <h3
            className="form-label text-center mb-4 text-dark"
            id="welcome_text"
          >
            Welcome!
          </h3>
        ))}
      <div
        className="container mt-3 mb-3"
        id="drag-and-drop-campaign"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        style={{
          border: "2px dashed #ccc",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <div className="mx-auto p-4">
          <div className="d-flex justify-content-center align-items-center">
            <div style={{ fontSize: "40px" }}>
              <img
                src={uploadFIleIcon}
                alt="upload-icon"
                style={{ width: "65px" }}
              />
            </div>
            <div className="upload-document-text ms-3 text-center">
              To Upload a new Campaign, drag and
              <br /> drop or{" "}
              <button
                onClick={handleUploadClick}
                className="text-primary btn btn-sm fw-bold fs-5 p-0"
              >
                Click Here
              </button>{" "}
              to select a file.
              <input
                type="file"
                id="upload_new_campaign"
                name="select_file"
                accept=".csv"
                placeholder="Select File"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="modal-dialog-custom"
        centered
      >
        <Modal.Body className="p-0">
          <div className="card campaign-card-rounded p-1" style={{ border: 0 }}>
            <div className="card-body">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name your Campaign"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    className="form-control campaign-input-rounded"
                  />
                </Form.Group>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <div className="d-grid gap-2 col-12 mx-auto">
                    <button
                      onClick={handleSave}
                      className="campaign-button-rounded campaignInfoModal"
                      type="button"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showFileModal}
        onHide={handleFileClose}
        className="modal-dialog-custom"
        centered
      >
        <Modal.Body className="p-0">
          <div className="card campaign-card-rounded p-1" style={{ border: 0 }}>
            <div className="card-body">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Name your Campaign"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    className="form-control campaign-input-rounded"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label
                    className="form-control campaign-input-rounded"
                    onClick={() => inputRef.current.click()}
                    style={{ cursor: "pointer" }}
                  >
                    {fileName ? fileName : "Select File"}
                  </Form.Label>
                  <Form.Control
                    ref={inputRef}
                    type="file" accept={".csv"}
                    id="upload_file_input_field"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Form.Group>
                <div className="d-grid gap-2 col-12 mx-auto">
                  <button
                    type="button"
                    className="campaign-button-rounded"
                    onClick={handleSave}
                  >
                    <span className="me-2">
                      <FaUpload color="white" size={16} />
                    </span>
                    Upload Campaign
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirmationModal}
        onHide={() => setConfirmationModal((prev) => !prev)}
        className="modal-dialog-custom confirmation-modal"
        centered
      >
        <Modal.Body className="p-0">
          <div className="text-center">
            <h5 className="modal-title mx-auto campaignInfo-modalLabel text-dark">
              Your leads have been uploaded successfully.{" "}
              <p className="text-break">Do you want to process now?.</p>
            </h5>
            <button
              onClick={statusProcesshandler}
              type="button"
              className="rounded-pill campaignInfo-save-btn"
            >
              Yes
            </button>
            <div
              onClick={() => setConfirmationModal((prev) => !prev)}
              className="form-text mt-2"
              style={{ cursor: "pointer" }}
            >
              Maybe later
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default UploadSection;
