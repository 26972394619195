import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import logo from "../assets/img/prospect-logo.png";
import { Link } from "react-router-dom";
import { Url } from "../functions/Url";

function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.email) tempErrors.email = "Email is required.";
    if (!formData.password) tempErrors.password = "Password is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch(`${Url}user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Login failed.");
      }

      const data = await response.json();
      console.log("User logged in successfully", data);
      localStorage.setItem("token", data.token); // Store the token in localStorage
      navigate("/dashboard"); // Redirect to the homepage or another route
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="piq-body">
      <div className="container pt-5 mb-5">
        <div className="mx-auto" style={{ maxWidth: "500px" }}>
          <div className="card shadow border card-rounded">
            <div className="card-body p-5">
              <div className="col-md-4 px-0">
                <img src={logo} className="img-fluid" alt="Prospect Logo" />
              </div>

              <h3 className="form-label mt-3">Log in to your account</h3>
              <form className="mt-3" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="text"
                      name="email"
                      required
                      className="form-control input-rounded"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>

                  <div className="col-md-12 mb-3">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      name="password"
                      required
                      className="form-control input-rounded"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <div className="text-danger">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3">
                  <Link to="/forgot-password" className="anchor-color">
                    Forgot Password?
                  </Link>
                </div>
                {apiError && <div className="text-danger">{apiError}</div>}
                <div className="d-grid gap-2 col-12 mb-3 mx-auto">
                  <button
                    className="btn btn-primary button-rounded"
                    type="submit"
                  >
                    Log in
                  </button>
                </div>
                <div className="form-text text-center mb-2">
                  Don't have an account?
                  <span>
                    <Link to="/signup" className="anchor-button-color ms-2">
                      Sign up
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
