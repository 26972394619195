import React, { useEffect, useState } from "react";
import logoWhite from "../assets/img/prospect-logo-white.webp";
import { FaBars, FaHome } from "react-icons/fa";
import { FaRightFromBracket } from "react-icons/fa6";

const NavBar = ({ sidebarhandler, isOpen }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="dashboard-nav"
      style={{ display: isMobile ? (isOpen ? "block" : "none") : "block" }}
    >
      {isMobile && (
        <header className="dashboard-toolbar">
          <a href="#!" onClick={sidebarhandler} className="menu-toggle">
            <FaBars size={20} />
          </a>
        </header>
      )}
      <div className="d-flex justify-content-center mt-3">
        <a href="#" className="brand-logo">
          <img
            src={logoWhite}
            style={{ width: "180px" }}
            alt="ProspectIQai Logo"
          />
        </a>
      </div>
      <nav className="dashboard-nav-list mt-3">
        <a href="#" className="dashboard-nav-item">
          <FaHome className="icon-home" /> Dashboard
        </a>
        <a
          href="#"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.href = "/";
          }}
          className="dashboard-nav-item mt-3"
        >
          <FaRightFromBracket className="icon-home" /> Logout{" "}
        </a>
      </nav>
    </div>
  );
};

export default NavBar;
