import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignTable from "./CampaignTable";
import UploadSection from "./UploadSection";
import Modals from "./Modals";
import NavBar from "./Navbar";
import filter from "../assets/img/filter-icon.svg";
import axios from "axios";
import { Url } from "../functions/Url";
import { FaBars } from "react-icons/fa";
// import {Chart} from 'chart.js/auto';
import {Doughnut, Bar} from 'react-chartjs-2';
import {ArcElement, Legend, Tooltip, Chart as ChartJs} from "chart.js/auto";


const Dashboard = () => {
  ChartJs.register(ArcElement, Tooltip, Legend);


  const innerLabel = {
  id: 'innerLabel',
  afterDatasetDraw(chart, args, pluginOptions) {
    const { ctx } = chart;
    const meta = args.meta;
    const xCoor = meta.data[0].x;
    const yCoor = meta.data[0].y;
    const perc = chart.data.datasets[0].data[0] / meta.total * 100;
    ctx.save();
    ctx.textAlign = 'center';
    ctx.font = '40px sans-serif';
    ctx.fillText(chart.data.datasets[0].data[1], xCoor, yCoor);
    ctx.restore();
  },
};



  const value = 300;
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [value, 1000 - value],
        backgroundColor: ['#F8F8F8', '#4040DC'],
		borderWidth: 0,
      },
    ],

  });

  const [data_bar, setDataBar] = useState({
    labels: ['Campaign 1', 'Campaign 2', 'Campaign 3'], // Campaign names
    datasets: [
      {
        label: 'Nurture',
        backgroundColor: '#8586f3',
        borderWidth: 0,
        data: [12000, 20000, 8000], // Example data for Nurture
      },
      {
        label: 'Boost',
        backgroundColor: '#4040DC',
        borderWidth: 0,
        data: [20000, 26000, 25000], // Example data for Boost
      },
      {
        label: 'Not Run',
        backgroundColor: '#4AA2E9',
        borderWidth: 0,
        data: [9000, 14000, 3500],}
    ]// Example data for Not Run
  });

  const [percentData, setPercentData] = useState({
    boostpercent: 0,
    nurturepercent: 0,
  });
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [campaigns, setCampaigns] = useState();
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [isOpen, setIsOpen] = useState(false);


  // doghmut chart start
  const [angle, setAngle] = useState(180);

  // const circularProgress = document.getElementById('circularProgress');
        // const progressValue = document.getElementById('progressValue');
        // const indicator = document.getElementById('indicator');
        // const indicatorValue = document.getElementById('indicatorValue');
        const [progress, setProgress] = useState(0);
        const [progressValue, setProgressValue] = useState('0');
        const [indicatorValue, setIndicatorValue] = useState('0');
        const [progressValueFontSize, setProgressValueFontSize] = useState('45px');

        const circularProgressStyle = progress > 0 ? {
  background: `conic-gradient(#F8F8F8 ${progress}%, #4040DC ${progress}% 100%)`
} : {};
        const [ariaValuenow, setAriaValuenow] = useState(0);
        const indicatorValueStyle = {
          transform: `translate(-50%, -50%) rotate(${angle}deg) translateY(-100px)`
            };

  let isDragging = false;

        const  updateProgress = (angle, remaingValue, totalRows) => {
            const progress = (angle / 360) * 100;
            setProgress(progress);
            // circularProgress.style.background = `conic-gradient(#F8F8F8 ${progress}%, #4040DC ${progress}% 100%)`;
            const value = Math.round((angle / 360) * 8);
            // progressValue.textContent = value + 'k';
            // indicatorValue.textContent = value + 'k';
          // setProgressValue('5k');
          // setIndicatorValue('8k');
          // setAngle(angle);
          // if(remaingValue === totalRows){
          //   setAngle(180);
          //
          // }
            // circularProgress.setAttribute('aria-valuenow', value);
          setAriaValuenow(value);
        };

        // const  handleMove = (event)=>  {
        //     if (!isDragging) return;
        //     const rect = circularProgress.getBoundingClientRect();
        //     const centerX = rect.left + rect.width / 2;
        //     const centerY = rect.top + rect.height / 2;
        //     const angle = Math.atan2(event.clientY - centerY, event.clientX - centerX) * (180 / Math.PI);
        //     const normalizedAngle = (angle + 360) % 360;
        //     updateProgress(normalizedAngle);
        // }

        // useEffect(() => {
        //           updateProgress(180);
        //
        //
        // }, []);


  const changeProgressValueFontSize = (progressValue) => {
    if (progressValue.trim().length === 3) {
      setProgressValueFontSize('45px');
    } else if (progressValue.trim().length === 4) {
      setProgressValueFontSize('35px');
    } else if (progressValue.trim().length === 5) {
      setProgressValueFontSize('30px');
    } else if (progressValue.trim().length === 6) {
      setProgressValueFontSize('25px');
    } else if (progressValue.trim().length === 7) {
        // fontSize = '20px';
      setProgressValueFontSize('20px');
    }else if (progressValue.trim().length > 7 && progressValue.trim().length < 13) {
      setProgressValueFontSize('15px');

    }else if (progressValue.trim().length > 12) {
        // fontSize = '10px';
      setProgressValueFontSize('10px');
    }
    };




  //  doghmut chart end

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();

      const dateOptions = {
        weekday: "long",
        month: "long",
        day: "numeric",
      };
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };

      const formattedDate = now.toLocaleDateString("en-US", dateOptions);
      const formattedTime = now.toLocaleTimeString("en-US", timeOptions);

      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000); // Update every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  const sidebarhandler = () => {
    setIsOpen((prev) => !prev);
  };

  const fetchDonutChart = async () => {
    try {
      const response = await axios.get(`${Url}campaigns/donutchart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      // setData(response.data);
      const totalRows = response.data.datasets[0].data[0];
      let iq1Rows = response.data.datasets[0].data[1];
      const remainingValue = totalRows - iq1Rows;
      // if(iq1Rows === 0){
      //   iq1Rows = 1;
      // }
      const anglePerc = (remainingValue/totalRows);
      const calculatedAngle = Math.round((anglePerc * 360));
      updateProgress(calculatedAngle, remainingValue, totalRows);
      setProgressValue(response.data.datasets[0].data[1]);
      changeProgressValueFontSize(response.data.datasets[0].data[1].toString());
      setIndicatorValue(response.data.datasets[0].data[0]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error fetching donut chart data:", error);
      }
    }
  };
  const fetchPercentData = async () => {
    try {
      const response = await axios.get(`${Url}campaigns/boostpercent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      setPercentData({
        boostpercent: response.data.BoostPercent,
        nurturepercent: response.data.NurturePercent,
      })


    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      } else {
        console.error("Error fetching percentage data:", error);
      }
    }
  };

  const fetchBarChart = async () => {
    try {
      const response = await axios.get(`${Url}campaigns/barchart`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        });
        setDataBar(response.data);
  }catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      navigate("/");
    }else{
      console.error("Error fetching bar chart data:", error);}
    }
  };
  const fetchCampaigns = async () => {
    try {
      const response = await axios.get(`${Url}campaigns/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      setCampaigns(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  useEffect(() => {
    fetchPercentData();
  }, []);
  useEffect(() => {
    fetchDonutChart();
  }, []);
  useEffect(() => {
    fetchBarChart();
  }, []);
  useEffect(() => {
    fetchCampaigns();
  }, []);

  return (
    <div className="dashboard">
      <NavBar sidebarhandler={sidebarhandler} isOpen={isOpen} />
      <div className="dashboard-app">
        <header className="dashboard-toolbar">
          <a href="#!" onClick={sidebarhandler} className="menu-toggle">
            <FaBars size={20} />
          </a>
        </header>
        <div className="p-4">
          <div>
            <h3 className="form-label text-dark">
              {currentDate} <span className="ms-3">{currentTime}</span>
            </h3>
          </div>

          {/*charts start*/}

          {/*<div className="top-graph-container p-1">*/}
          {/*  <div className="row g-2">*/}
          {/*    <div className="col-lg-6">*/}
          {/*      <div className="row g-2">*/}
          {/*        <div className="col-lg-6">*/}

          {/*          <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>*/}
          {/*            <div className="card-body p-3">*/}
          {/*              <div className="cl-filter-icon">*/}
          {/*                <label><span><img className="img-fluid" src={filter}/></span>Records*/}
          {/*                  Run</label>*/}
          {/*                <hr className="cl-heading-border"/>*/}
          {/*                <div className="text-center">*/}
          {/*                  /!*<canvas id="myDoughnutChart" className="chart_canva"></canvas>*!/*/}
          {/*                  <Doughnut data={data} options={{*/}
          {/*                    responsive: true,*/}
          {/*                    maintainAspectRatio: false,*/}
          {/*                    plugins: {*/}
          {/*                      tooltip: {*/}
          {/*                        enabled: false*/}
          {/*                      },*/}

          {/*                    }*/}
          {/*                  }} plugins={[innerLabel]}   className={"chart_canva"}/>*/}


          {/*                </div>*/}
          {/*              </div>*/}

          {/*            </div>*/}
          {/*          </div>*/}


          {/*        </div>*/}
          {/*        <div className="col-lg-6">*/}

          {/*          <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px", border: "none"}}>*/}
          {/*            <div className="card-body p-3">*/}
          {/*              <div className="cl-filter-icon">*/}
          {/*                <label><span><img className="img-fluid" src={filter}/></span>Boost</label>*/}
          {/*                <hr className="cl-heading-border"/>*/}
          {/*                <div className="text-center canva_middle_size">*/}
          {/*                  <h5 className="d-heading-type-1">{percentData.boostpercent}%</h5>*/}
          {/*                </div>*/}
          {/*              </div>*/}

          {/*            </div>*/}
          {/*          </div>*/}

          {/*          <div className="card mt-2 mt-3-991"*/}
          {/*               style={{backgroundColor: "#e4edf4", borderRadius: "15px", border: "none"}}>*/}
          {/*            <div className="card-body p-3">*/}
          {/*              <div className="cl-filter-icon">*/}
          {/*                <label><span><img className="img-fluid" src={filter}/></span>Nurture</label>*/}
          {/*                <hr className="cl-heading-border"/>*/}
          {/*                <div className="text-center canva_middle_size">*/}
          {/*                  <h5 className="d-heading-type-1">{percentData.nurturepercent}%</h5>*/}
          {/*                </div>*/}
          {/*              </div>*/}

          {/*            </div>*/}
          {/*          </div>*/}


          {/*        </div>*/}
          {/*      </div>*/}

          {/*    </div>*/}
          {/*    <div className="col-lg-6">*/}

          {/*      <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>*/}
          {/*        <div className="card-body p-3">*/}
          {/*          <div className="cl-filter-icon">*/}
          {/*            <label><span><img className="img-fluid" src={filter}/></span>Campaigns</label>*/}
          {/*            <hr className="cl-heading-border"/>*/}

          {/*            /!*<canvas id="myBarChart" className="chart_canva"></canvas>*!/*/}
          {/*            <Bar data={data_bar} options={{*/}
          {/*              responsive: true,*/}
          {/*              maintainAspectRatio: false,*/}
          {/*            }} className={"chart_canva"}/>*/}
          {/*          </div>*/}

          {/*        </div>*/}
          {/*      </div>*/}

          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="top-graph-container p-1">
            <div className="row g-2">
              <div className="col-lg-6">
                <div className="row g-2">
                  <div className="col-lg-6">

                    <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>
                      <div className="card-body p-3">
                        <div className="cl-filter-icon">
                          <label><span><img className="img-fluid" src={filter}/></span>Records
                            Run</label>
                          <hr className="cl-heading-border"/>
                          <div className="text-center chart_canva_doughnut_container">

                            <div className="progress-container">
                              <div className="circular-progress" id="circularProgress" role="slider" aria-valuemin="0"
                                   aria-valuemax="100" aria-valuenow={ariaValuenow} style={circularProgressStyle}>
                                <div className="inner-circle">
                                  <span style={{fontSize: progressValueFontSize, fontWeight: "bold"}} className="progress-value" id="progressValue">{progressValue}</span>
                                </div>
                              </div>
                              <div className="indicator" id="indicator" style={indicatorValueStyle}>
                                <span className="indicator-value" id="indicatorValue">{indicatorValue}</span>
                              </div>
                            </div>


                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                  <div className="col-lg-6">

                    <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>
                      <div className="card-body p-3">
                        <div className="cl-filter-icon">
                          <label><span><img className="img-fluid" src={filter}/></span>Prime</label>
                          <hr className="cl-heading-border"/>
                          <div className="text-center canva_middle_size">
                            <h5 className="d-heading-type-1">{percentData.boostpercent}%</h5>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="card mt-2 mt-3-991"
                         style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>
                      <div className="card-body p-3">
                        <div className="cl-filter-icon">
                          <label><span><img className="img-fluid" src={filter}/></span>Select</label>
                          <hr className="cl-heading-border"/>
                          <div className="text-center canva_middle_size">
                            <h5 className="d-heading-type-1">{percentData.nurturepercent}%</h5>
                          </div>
                        </div>

                      </div>
                    </div>


                  </div>
                </div>

              </div>
              <div className="col-lg-6">

                <div className="card mt-3" style={{backgroundColor: "#e4edf4", borderRadius: "15px",border: "none"}}>
                  <div className="card-body p-3">
                    <div className="cl-filter-icon">
                      <label><span><img className="img-fluid" src={filter}/></span>Campaigns</label>
                      <hr className="cl-heading-border"/>

                      <Bar className={"chart_canva"} data={data_bar} options={{
                        responsive: true,
                        maintainAspectRatio: false,

                      }}/>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>

          {/*charts end*/}
          <div
              className="card mt-2"
              style={{
                backgroundColor: "#e4edf4",
                borderRadius: "15px",
                border: "none",
              }}
          >
            <div className="card-body p-4">
              <div className="cl-filter-icon">
                <label>
                  <span>
                    <img className="img-fluid" src={filter} alt="filter-icon"/>
                  </span>
                  Campaigns
                </label>
                <hr className="cl-heading-border"/>
              </div>
              <div className="form-floating mt-3">
                <div className="p-2 cl-internal-area-container">
                  <div className="scroll-area ps-4 pe-4">
                    <CampaignTable data={campaigns} setData={fetchCampaigns}/>
                    <UploadSection data={campaigns} setData={fetchCampaigns}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals/>
    </div>
  );
};

export default Dashboard;
