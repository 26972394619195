// ForgotPassword.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/css/style.css";
import logo from "../assets/img/prospect-logo.png";
import { Url } from "../functions/Url";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [message, setMessage] = useState("");

  const validate = () => {
    let tempErrors = {};
    if (!email) tempErrors.email = "Email is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch(`${Url}user/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to send reset link.");
      }

      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="piq-body">
      <div className="container pt-5 mb-5">
        <div className="mx-auto" style={{ maxWidth: "500px" }}>
          <div className="card shadow border card-rounded">
            <div className="card-body p-5">
              <div className="col-md-4 px-0">
                <img src={logo} className="img-fluid" alt="Prospect Logo" />
              </div>

              <h3 className="form-label mt-3">Forgot Password</h3>
              <form className="mt-3" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="email"
                      required
                      className="form-control input-rounded"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                </div>
                {apiError && <div className="text-danger">{apiError}</div>}
                {message && <div className="text-success">{message}</div>}
                <div className="d-grid gap-2 col-12 mb-3 mx-auto">
                  <button
                    className="btn btn-primary button-rounded"
                    type="submit"
                  >
                    Send Reset Link
                  </button>
                </div>
                <div className="form-text text-center mb-2">
                  Remember your password?
                  <span>
                    <Link to="/" className="anchor-button-color ms-2">
                      Log in
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
